import React, { Component } from 'react';
import NavBottom from './NavBottom';
import { Button } from 'react-bootstrap';
import '../App.css'; // Ensure App.css is in src/

export class DashBoardGate extends Component {
    render() {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                {/* Main Container */}
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center', // Horizontally centered
                        marginTop: '20px', // Add a margin to position the box closer to the top
                    }}
                >
                    {/* Purple Box */}
                    <div
                        style={{
                            color: 'purple',
                            textAlign: 'center',
                            height: '400px',
                            width: '600px',
                            border: '0px solid purple',
                            padding: '20px',
                            borderRadius: '25px',
                        }}
                    >
                        {/* Message */}
                        <h3>
                            Only Chapter President, Vice President, Treasurer, Secretary, Ambassador, and Chief Steward can view the Union Intelligence Dashboard.
                        </h3>
                        {/* Button */}
                        <Button
                            className="btn-purple" // Use a custom class for styling
                            href="/reportembed"
                            style={{ marginTop: '20px' }}
                        >
                            Proceed to Dashboard
                        </Button>
                    </div>
                </div>
                {/* Navigation Bar */}
                <NavBottom />
            </div>
        );
    }
}

export default DashBoardGate;
