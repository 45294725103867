import React, { useState, useEffect, useMemo, useCallback } from "react";
import axios from "axios";
import Table from "./RSVPTableTemplate";
import { Button, Spinner } from "react-bootstrap";

export function RSVPUpcomingEvents({ memberID }) {
  const [data, setData] = useState([]);
  const [registeredEvents, setRegisteredEvents] = useState(new Set()); // Track registered event IDs
  const [loading, setLoading] = useState(true); // Loading state

  // ✅ **Fetch Events & Registered Events**
  useEffect(() => {
    const fetchEvents = async () => {
      try {
        // Fetch upcoming events
        const eventsResult = await axios.get(
          "https://prod-100.westus.logic.azure.com:443/workflows/a389ca49a9ec42a69ea800fa4bfb8597/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=U0ZtqNvNoa6Fo1OusEaUJfn6q5Gy3Qr3e6hjyXINsUk"
        );
        setData(eventsResult.data);

        // Fetch registered events
        const registeredResult = await axios.get(
          `https://prod-52.westus.logic.azure.com/workflows/db71493270564ed19d57674acc60cebc/triggers/manual/paths/invoke/${memberID}?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=r1RHW-JN767bYHKn8yEoINy0tjTlktwWFSJRJ_sP5EA`
        );

        // Store registered event IDs in a Set
        setRegisteredEvents(new Set(registeredResult.data.map(event => event.EventID)));
      } catch (error) {
        console.error("Error fetching events:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, [memberID]);

  // ✅ **Register for an Event**
  const register = useCallback(async (eventID) => {
    try {
      await axios.get(
        `https://prod-08.westus.logic.azure.com/workflows/335a123bf43d4895b0a4871a6da05928/triggers/manual/paths/invoke/${memberID}?EventID=${eventID}&api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=jfLaO_vo-jSDVkqGiHkaUDLRJe7Kh4GACI94WVZr9CU`
      );

      // ✅ Update state to reflect registration
      setRegisteredEvents(prev => new Set([...prev, eventID]));
    } catch (error) {
      console.error("Registration failed:", error);
    }
  }, [memberID]);

  // ✅ **Unregister from an Event**
  const unregister = useCallback(async (eventID) => {
    try {
      await axios.get(
        `https://prod-65.westus.logic.azure.com/workflows/4c61cc77ccfc4735aff8774c552fd495/triggers/manual/paths/invoke/${eventID}?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=YgojLRCQI2VtV3Xq81g8GO_H-jqupXbfcBfHriH7Uvo`
      );

      // ✅ Update state to reflect unregistration
      setRegisteredEvents(prev => {
        const updated = new Set(prev);
        updated.delete(eventID);
        return updated;
      });
    } catch (error) {
      console.error("Unregistration failed:", error);
    }
  }, [memberID]);

  // ✅ **Format Start Date (Left-Justified & Top-Aligned)**
  const formatDateTime = (startDateString, endDateString) => {
    const startDate = new Date(startDateString);
    const endDate = new Date(endDateString);

    return (
      <div style={{ textAlign: "left", verticalAlign: "top", whiteSpace: "nowrap" }}>
        <strong>{`${startDate.getMonth() + 1}/${startDate.getDate()}/${startDate
          .getFullYear()
          .toString()
          .slice(-2)}`}</strong>
        <br />
        {`${startDate.toLocaleTimeString([], { hour: "numeric", minute: "2-digit", hour12: true })} - ${endDate.toLocaleTimeString([], { hour: "numeric", minute: "2-digit", hour12: true })}`}
      </div>
    );
  };

  // ✅ **Define Table Columns (Top-Aligned & Left-Justified)**
  const columns = useMemo(
    () => [
      {
        Header: "Events",
        columns: [
          {
            Header: "Event Name",
            accessor: "Name",
            Cell: ({ value }) => <div style={{ textAlign: "left", verticalAlign: "top" }}>{value}</div>,
          },
          {
            Header: "Start Date",
            accessor: "StartDate",
            width: 180,
            Cell: ({ row }) => (
              <div style={{ textAlign: "left", verticalAlign: "top" }}>
                {formatDateTime(row.original.StartDate, row.original.EndDate)}
              </div>
            ),
            sortType: (a, b) => new Date(a.original.StartDate) - new Date(b.original.StartDate),
          },
          {
            Header: "Location",
            accessor: "LocationName",
            Cell: ({ value }) => <div style={{ textAlign: "left", verticalAlign: "top" }}>{value}</div>,
          },
          {
            Header: "Action",
            accessor: (d) => (
              <div style={{ textAlign: "left", verticalAlign: "top" }}>
                {registeredEvents.has(d.EventID) ? (
                  <Button className="button-same-width mx-2 btn-events" variant="danger" onClick={() => unregister(d.EventID)}>
                    Unregister
                  </Button>
                ) : (
                  <Button className="button-same-width mx-2 btn-events" onClick={() => register(d.EventID)}>
                    Register
                  </Button>
                )}
              </div>
            ),
          },
        ],
      },
    ],
    [register, unregister, registeredEvents]
  );

  return (
    <div>
      {loading ? (
        <div className="div-loading" style={{ color: "purple", display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100vh" }}>
          <Spinner animation="border" style={{ color: "purple" }} />
          <h1>Fetching Events...</h1>
        </div>
      ) : data.length > 0 ? (
        <div style={{ height: "100vh", overflowY: "auto" }}>
          <Table
            columns={columns}
            data={data}
            rowProps={(row) => ({
              style: { height: '50px' } // Set the desired height for the table rows
            })}
          />
        </div>
      ) : (
        <div className="div-loading" style={{ color: "purple", display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100vh" }}>
          <h1>No upcoming events.</h1>
        </div>
      )}
    </div>
  );
}