import React, { Component, useState, useEffect, useMemo } from 'react';
//import { QrReader } from 'react-qr-reader';
import axios from 'axios'; 
import Table from "./RSVPTableTemplate";
import { Button, Spinner } from 'react-bootstrap';

class Barcode extends Component {

    // constructor() {
    //     super()
    //     //this.handleEventSelection = this.handleEventSelection.bind(this)
    // }

    audio = null;

    componentDidMount() {
        this.audio = new Audio('https://media.geeksforgeeks.org/wp-content/uploads/20190531135120/beep.mp3');
        this.audio.load();
    }

    playAudio() {
        const audioPromise = this.audio.play()
        if (audioPromise !== undefined) {
          audioPromise
            .then(_ => {
              // autoplay started
            })
            .catch(err => {
              // catch dom exception
              console.info(err)
            })
        }
    }

    state = {
        result: 'Please scan your Member ID',
        eventID: '',
        memberID: ''
    }

    handleEventSelection = ( eventID, eventName  )  => {
        //alert(`User clicked on event: ${eventID}` );
        this.setState({
            eventID: eventID,
            eventName: eventName
        })
    }

    // Send the user back to the event selection component
    handleEventClearPushed = () => {
        this.setState({
            eventID: '',
            eventName: ''      
        })
    }

    register(memberID) {
        console.log(`Registering member ${memberID} for event ${this.state.eventID}`);

        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
            }
        };
        
        let uri = `https://prod-08.westus.logic.azure.com/workflows/335a123bf43d4895b0a4871a6da05928/triggers/manual/paths/invoke/${memberID}?EventID=${this.state.eventID}&api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=jfLaO_vo-jSDVkqGiHkaUDLRJe7Kh4GACI94WVZr9CU`;
        axios.get(uri, {
        }, axiosConfig)
        .then((response) => {
            console.log(response);
            //alert(`Registration successful for ${this.state.eventID }`);

            // Clear the member after registering the user
            this.setState({
                //eventID: this.state.eventID,
                result: `Registration Successful for ${ memberID }`,
                memberID: ''
            });

            this.playAudio();

        }, (error) => {
            console.log(error);

            // Clear the member after registering the user
            this.setState({
                //eventID: this.state.eventID,
                result: `Registration Failed for ${ memberID }`,
                memberID: ''
            });
        });


    }

 
    handleScan = data => {
        if (data) {
            try{
                data = data.replace("http://",""); // unnecessary for SEIU badge but useful for raw barcodes
                data = JSON.parse(data);

                // This code is specfic to the badge provided by Boyan
                this.setState({
                    //result: data.result,
                    memberID: data.result,
                    result: `MemberID ${data.result} scanned`
                })

                // Register the user
                this.register( data.result);

            }
            catch (error){
                console.log(error);
                // Fall back to raw data
                this.setState({                
                    //result: data,
                    memberID: data,
                    result: `MemberID ${data} scanned`
                });

                // Register the user
                this.register(data);     
            }
        }
    }
    handleError = err => {
        console.error(err)
    }

    render() { 
        return (
        <div>

            {
                this.state.eventID 
                ?
                    <div style={{width:'100%', textAlign:'center', margin:'auto'}}>
                        <div style={{  maxHeight:'75%', maxWidth:'100%', paddingTop:'5px'}}>
                           {/*  <QrReader
                            delay={300}
                            onError={this.handleError}
                            onScan={this.handleScan}
                            facingMode='user'
                            style={{ margin:'auto', textAlign:'center', width:'35%' }}
                            /> */}
                        </div>
                        <div>
                            <h2 style={{color:'blue'}} >{this.state.result}</h2>
                            <h2>Event Selected: {this.state.eventName} ({this.state.eventID})</h2>
                            {/* <h2>Member Scanned:{this.state.memberID}</h2> */}
                            <Button className="mx-2 btn-events" onClick={() => this.handleEventClearPushed( )}>Clear Event</Button>
                        </div>
                    </div>
                :
                    <div style={{ textAlign:'center', paddingTop:'3%', paddingBottom:'3%'}} >
                            <h1>Please select an event</h1>
                            <RSVPEvents onClick={ this.handleEventSelection } />
                    </div>
            } 
        </div>
        )
    }
}

export default Barcode;


// Pass in the handEventSelection function from the parent
function RSVPEvents( { onClick } ) {

    const [allEvents, setAllEvents] = useState(0);
    const [data, setData] = useState(0);
    const [locations, setLocations] = useState();
    const [selectedLocation, setSelectedLocation] = useState();

        // Pass our call up
        function handleEventClicked(eventID, eventName) {
            //alert(eventID)
            onClick(eventID, eventName);
        }




  // Using useEffect to call the API once mounted then set the state
  useEffect(() => {
    (async () => {

        let queryParams = new URLSearchParams(window.location.search);
        const locationNameFromQueryString = queryParams.get("location");

        if (!allEvents) { 
            // Events are not in state - query them
            const result = await axios(
                "https://prod-100.westus.logic.azure.com:443/workflows/a389ca49a9ec42a69ea800fa4bfb8597/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=U0ZtqNvNoa6Fo1OusEaUJfn6q5Gy3Qr3e6hjyXINsUk"
            );

            setAllEvents(result.data); // The unfiltered raw list of all events (Not displayed)
            setData(result.data); // List of potentially filtered data that is displayed

            // Get a list of all locations
            let _locations = [];
            result.data.forEach((element) => {
              if (element.LocationName === null) {
                // ignore - left for readability
              } else {
                if (_locations.indexOf(element.LocationName) === -1) {
                  _locations.push(element.LocationName);
                }
              }
            });
      
            // Persist the locations to state
            setLocations(_locations);
        }
        else { 
            // The events and locations are already in state
            let effectiveSelectedLocation = null;

            locationNameFromQueryString ? effectiveSelectedLocation = locationNameFromQueryString : effectiveSelectedLocation = selectedLocation;

            // The user selected a value from the locations drop down
            if (effectiveSelectedLocation && effectiveSelectedLocation !== "See all locations") {
                let _data = [];
                allEvents.forEach((element) => {
                if (element.LocationName === effectiveSelectedLocation) {
                    // Puse this location since the user wants to see all locations
                    _data.push(element);
                }
                });
                setData(_data);
                setSelectedLocation(effectiveSelectedLocation);
            } else {
                // There's no location selected - reset the all events to the allEvents
                setData(allEvents);
            }
        }

    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation, locations]); // Refresh when the user selects a location from the drop down OR when the initial setLocations  is run



        // Setup Table
        const columns = useMemo(
            () => [
            {
                //Table Header - Upcoming Events
                Header: "Events",
                //Columns
                columns: [
                {
                    Header: "Event Name",
                    accessor: "Name"
                },
                {
                    Header: "Start Date", 
                    accessor: "StartDate",
                    //sortType: "datetime",
                    //Cell: ( { value }) => { return format(new Date(value), 'MM/dd/yyyy h:mm a')}, 
                    sortType: (a, b) => {
                            var a1 = new Date(a.values.StartDate);
                            var b1 = new Date(b.values.StartDate);
                            //console.log(a1)
                            //console.log(b1)
                            if(a1>b1)
                                return 1;
                            else if(a1<b1)
                                return -1;
                            else
                                return 0;
                    }
                },
                {
                    Header: "End Date",
                    accessor: "EndDate",
                    sortType: (a, b) => {
                        var a1 = new Date(a.values.EndDate);
                        var b1 = new Date(b.values.EndDate);
                        console.log(a1)
                        console.log(b1)
                        if(a1>b1)
                            return 1;
                        else if(a1<b1)
                            return -1;
                        else
                            return 0;
                }
                },
                {
                    Header: "Event ID",
                    accessor: "EventID"
                } ,         {
                    Header: "Location",
                    accessor: "LocationName",
                  }
                ,
                {
                    Header: "Attendance",
                    accessor: d => {
                        return <Button className="mx-2 btn-events" onClick={() => handleEventClicked( d.EventID, d.Name )}>Select for Scan</Button>
                    }
                }
                ]
            }
            ],
            // eslint-disable-next-line react-hooks/exhaustive-deps
            []
        );

        const handleSelectedLocationChange = (e) => {
            e.preventDefault();
            setSelectedLocation(e.target.value);
          };

          
          return (
            <div>
              {data ? (
                <div>
                  <select
                    style={{
                      width: "350px",
                      height: "40px",
                      fontSize: "15px",
                      fontWeight: "bold",
                      margin: "5px",
                    }}
                    variant="info"
                    onChange={handleSelectedLocationChange}
                  >
                    {selectedLocation ? (
                      <option>See all locations</option>
                    ) : (
                      <option>Choose a location</option>
                    )}
                    {locations ? (
                      locations.map((loc) => <option key={loc} selected={loc===selectedLocation}   >{loc}</option>)
                    ) : (
                      <div />
                    )}
                  </select>
                  <Table columns={columns} data={data} />
                </div>
              ) : (
                <div
                  class="div-loading"
                  style={{
                    color: "purple",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Spinner animation="border" style={{ color: "purple" }} />
                  <h1>Fetching Upcoming Events... </h1>
                </div>
              )}
            </div>
          );
}
