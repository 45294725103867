import React, { Component } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import axios from 'axios';
import NavBottom from './NavBottom';
import '../App.css';

export class NewHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            embedInfo: null,
            embedAddress: '',
            embedReportID: '',
            error: null,
        };
    }

    componentDidMount() {
        const effectiveUser = '&effectiveUser=113964'; // Replace with dynamic user if needed
        const queryAddress = `https://seiu-twr-powerbiembedfunc.azurewebsites.net/api/MRCPRDHomeKPIEmbed?code=AzxCyhFfaWLcSddT3H5zsxeGag8aR3e9R/ctdnWEwe678WRJbGDXVw==${effectiveUser}`;

        // Fetch Power BI embed details
        axios
            .get(queryAddress)
            .then((response) => {
                const data = response.data;
                this.setState({
                    embedInfo: data.EmbedToken,
                    embedAddress: data.EmbedUrl,
                    embedReportID: data.ReportId,
                });
            })
            .catch((error) => {
                console.error('Error fetching embed data:', error);
                this.setState({ error: 'Failed to load the report. Please try again later.' });
            });
    }

    render() {
        const { embedInfo, embedAddress, embedReportID, error } = this.state;

        if (error) {
            return (
                <div style={{ color: 'red', textAlign: 'center', marginTop: '20px' }}>
                    <h2>{error}</h2>
                </div>
            );
        }

        if (!embedInfo) {
            return (
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <h2>Loading Report...</h2>
                </div>
            );
        }

        return (
            <div style={{ height: 'calc(100vh - 50px)', padding: '10px' }}>
                <PowerBIEmbed
                    embedConfig={{
                        type: 'report',
                        id: embedReportID,
                        embedUrl: embedAddress,
                        accessToken: embedInfo,
                        tokenType: models.TokenType.Embed,
                        settings: {
                            panes: {
                                filters: { visible: false },
                                pageNavigation: { visible: false },
                            },
                        },
                    }}
                    cssClassName="powerbi-report-container"
                    onLoad={() => console.log('Report loaded successfully')}
                    onRender={() => console.log('Report rendered successfully')}
                    onError={(event) => console.error('Error rendering Power BI report:', event.detail)}
                />
                <NavBottom />
            </div>
        );
    }
}

export default NewHome;
