import React, { Component } from 'react';
import NavBottom from './NavBottom';
import { RSVPUpcomingEvents } from './RSVPUpcomingEvents';

class RSVPEventsPage extends Component {
  componentDidMount() {
    console.log("RSVPEventsPage memberID:", this.props.memberID);
  }

  render() {
    return (
      <div>
        <div 
          style={{ 
            display: "flex", 
            flexDirection: "column", 
            height: "calc(90vh - 100px)"
          }}
        >
          {/* Events Table (Previously "Upcoming Events") */}
          <div 
            className="events-section events-list" 
            style={{ padding: "10px", flexGrow: 1, overflowY: "auto" }}
          >
            <RSVPUpcomingEvents memberID={this.props.memberID} />
          </div>
        </div>
        <NavBottom />
      </div>
    );
  }
}

export default RSVPEventsPage;