import React from 'react';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';

const NavTopSubMenu = ({ logout }) => {
    const handleToggle = () => {
        // Handle toggle logic here
    };

    const handleClose = () => {
        // Handle close logic here
    };

    const handleLogout = () => {
        handleClose();
        logout();
    };

    return (
        <Container>
            {/* Navbar Toggle Button */}
            <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle} />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="subnav-links">
                    {/* Main Navigation Links */}
                    <Nav.Item className="nav-item">
                        <Nav.Link href="/dashboardgate" onClick={handleClose} className="nav-link nav-item-font">
                            Union Intelligence
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="nav-item">
                        <Nav.Link href="https://teach.seiu1021.org/loginsaml.aspx" target="_blank" rel="noreferrer" onClick={handleClose} className="nav-link nav-item-font">
                            Digital Learning
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="nav-item">
                        <Nav.Link href="/rsvpeventspage" onClick={handleClose} className="nav-link nav-item-font">
                            Event Registration
                        </Nav.Link>
                    </Nav.Item>
                    {/* Dropdowns */}
                    <NavDropdown title="Contracts/Bylaws" id="contracts-bylaws-dropdown" className="dropdown nav-item-font">
                        <NavDropdown.Item href="/contractdocs" onClick={handleClose} className="dropdown-item nav-item-font">
                            My Contract and Bylaws
                        </NavDropdown.Item>
                        <NavDropdown.Item href="https://d3jpbvtfqku4tu.cloudfront.net/img/ConstitutionBylaws.pdf" target="_blank" rel="noreferrer" onClick={handleClose} className="dropdown-item nav-item-font">
                            International Constitution and Bylaws
                        </NavDropdown.Item>
                        <NavDropdown.Item href="https://www.seiu1021.org/sites/main/files/file-attachments/seiu1021-bylaws_09-2012.pdf?1595547741" target="_blank" rel="noreferrer" onClick={handleClose} className="dropdown-item nav-item-font">
                            SEIU Local 1021 Bylaws
                        </NavDropdown.Item>
                        <NavDropdown.Item href="https://www.seiu1021.org/find-your-chapter-contract" target="_blank" rel="noreferrer" onClick={handleClose} className="dropdown-item nav-item-font">
                            SEIU Local 1021 all contracts
                        </NavDropdown.Item>
                    </NavDropdown>

                    <NavDropdown title="Tool Kits" id="tool-kits-dropdown" className="dropdown nav-item-font">
                        <NavDropdown.Item href="https://www.seiu1021.org/sites/main/files/file-attachments/chapter_leader_resources_request_manual.pdf" target="_blank" rel="noreferrer" onClick={handleClose} className="dropdown-item nav-item-font">
                            How to request digital resources
                        </NavDropdown.Item>
                        <NavDropdown.Item href="https://www.seiu1021.org/sites/main/files/mobile_app_installation_guide.pdf" target="_blank" rel="noreferrer" onClick={handleClose} className="dropdown-item nav-item-font">
                            Mobile App Manual
                        </NavDropdown.Item>
                        <NavDropdown.Item href="https://www.seiu1021.org/sites/main/files/union_intelligence_mrc_portal_user_guide.pdf" target="_blank" rel="noreferrer" onClick={handleClose} className="dropdown-item nav-item-font">
                            Union Intelligence Manual
                        </NavDropdown.Item>
                        <NavDropdown.Item href="https://vimeo.com/757658689" target="_blank" rel="noreferrer" onClick={handleClose} className="dropdown-item nav-item-font">
                            Portal Video Guide
                        </NavDropdown.Item>
                        <NavDropdown.Item href="https://www.seiumb.com/" target="_blank" rel="noreferrer" onClick={handleClose} className="dropdown-item nav-item-font">
                            Member Benefits & Discounts
                        </NavDropdown.Item>
                    </NavDropdown>

                    <NavDropdown title="Resources" id="resources-dropdown" className="dropdown nav-item-font">
                        <NavDropdown.Item href="https://memberlink.seiu1021.org/SEIU1021MemberApplicationStep1.aspx" target="_blank" rel="noopener noreferrer" onClick={handleClose} className="dropdown-item nav-item-font">
                        Membership Application
                        </NavDropdown.Item>
                        <NavDropdown.Item href={require("../docs/Member-general-expenses_policy-form_4.19.16.pdf")} target="_blank" rel="noopener noreferrer" onClick={handleClose} className="dropdown-item nav-item-font">
                        Member General Expense Policy
                        </NavDropdown.Item>
                        <NavDropdown.Item href="https://www.seiu1021.org/sites/main/files/file-attachments/policy_code_of_conduct_ebd-approved_2021-10-23.pdf?1635291070" target="_blank" rel="noopener noreferrer" onClick={handleClose} className="dropdown-item nav-item-font">
                        Code of Conduct
                        </NavDropdown.Item>
                        <NavDropdown.Item href="https://www.seiu1021.org/sites/main/files/file-attachments/policy_localwide-code_of_ethics_ebd-adopted_2019-09-29.pdf?1572926574" target="_blank" rel="noopener noreferrer" onClick={handleClose} className="dropdown-item nav-item-font">
                        Code of Ethics Policy
                        </NavDropdown.Item>
                        <NavDropdown.Item href="https://www.seiu1021.org/sites/main/files/file-attachments/policy_disputes-between-members_rev_ebd-approved_2014-11-22_1.pdf?1523993873" eventKey="4.1" target="_blank" rel="noopener noreferrer" onClick={handleClose} className="dropdown-item nav-item-font">
                        Policy Disputes Between Members
                        </NavDropdown.Item>
                        <NavDropdown.Item href={require("../docs/Chapter Authorization to Issue Chapter Funds.pdf")} eventKey="4.1" target="_blank" rel="noopener noreferrer" onClick={handleClose} className="dropdown-item nav-item-font">
                        Chapter Authorization to <br />  issue Chapter Funds
                        </NavDropdown.Item>
                        <NavDropdown.Item href={require("../docs/Chapter Signatory Form[99313].pdf")} eventKey="4.1" target="_blank" rel="noopener noreferrer" onClick={handleClose} className="dropdown-item nav-item-font">
                        Chapter Signatory
                        </NavDropdown.Item>
                        <NavDropdown.Item href="https://www.callhub.io" eventKey="4.1" target="_blank" rel="noopener noreferrer" onClick={handleClose} className="dropdown-item nav-item-font">
                        Virtual Phonebank
                        </NavDropdown.Item>
                        <NavDropdown.Item href="https://seiu-twr.azurewebsites.net/static/media/MRC%20Portal%20FAQ.acfd3747b4d839a1243c.pdf" eventKey="4.1" target="_blank" rel="noopener noreferrer" onClick={handleClose} className="dropdown-item nav-item-font">
                        Frequently Asked Questions
                        </NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Item className="nav-item">
                        <Nav.Link href="https://seiu1021.freshdesk.com/widgets/feedback_widget/new" onClick={handleClose} className="nav-link nav-item-font">
                        Help
                        </Nav.Link>
                   </Nav.Item>
                    <Nav.Item className="nav-item">
                      <Nav.Link href="#" onClick={handleLogout} className="nav-link nav-item-font">
                        Logout
                      </Nav.Link>
                   </Nav.Item>
                </Nav>
            </Navbar.Collapse>
        </Container>
    );
};

export default NavTopSubMenu;