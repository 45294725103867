import Nav from 'react-bootstrap/Nav';
import { Navbar, Container } from 'react-bootstrap';
import React from 'react';

const NavBottom = (props) => {
    const isMobile = window.innerWidth <= 768; // Check if the screen width is less than or equal to 768px

    if (isMobile) return null; // Don't render NavBottom on mobile

    return (
        <Navbar 
            className="navbar-bottom" 
            style={{ backgroundColor: '#3A2C86', width: '100%' }} 
            variant="dark"
            fixed="bottom"  // Fixes navbar to the bottom of the page
        >
            <Container fluid className="d-flex justify-content-between align-items-center">
                {/* Removed SEIU Logo */}
                {/* Removed Digital Learning Banner */}
            </Container>
        </Navbar>
    );
}

export default NavBottom;