import React, { Component } from 'react';
import { Report } from 'powerbi-report-component';
import axios from 'axios';
import NavBottom from './NavBottom';
import { RSVPUpcomingEventsMain } from './RSVPUpcomingEventsMain';
import { Spinner } from 'react-bootstrap';

const extraSettings = {
    filterPaneEnabled: false,
    navContentPaneEnabled: false,
};

export class Home extends Component {
    constructor(props) {
        super(props);
        this.report = null;
        this.state = {
            embedInfo: "",
            embedAddress: "",
            embedReportID: "",
            viewportHeight: window.innerHeight, // Capture initial viewport height
        };
    }

    componentDidMount() {
        const effectiveUser = `&effectiveUser=${this.props.memberID}`;
        const queryAddress = `https://seiu-twr-powerbiembedfunc.azurewebsites.net/api/MRCPRDHomeKPIEmbed?code=AzxCyhFfaWLcSddT3H5zsxeGag8aR3e9R/ctdnWEwe678WRJbGDXVw==${effectiveUser}`;

        axios.get(queryAddress)
            .then(response => response.data)
            .then((data) => {
                this.setState({
                    embedInfo: data.EmbedToken,
                    embedAddress: data.EmbedUrl,
                    embedReportID: data.ReportId
                });
            });

        // Update viewport height on resize
        window.addEventListener('resize', this.updateViewportHeight);
    }

    componentWillUnmount() {
        // Clean up resize listener
        window.removeEventListener('resize', this.updateViewportHeight);
    }

    updateViewportHeight = () => {
        this.setState({ viewportHeight: window.innerHeight });
    };

    render() {
        const { viewportHeight, embedInfo, embedAddress, embedReportID } = this.state;

        return (
            <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh", overflow: "hidden" }}>
                <div style={{ display: "flex", flexDirection: "column", padding: "10px", width: "100%", flex: "1 0 auto" }}>
                    {/* Alert Message */}
                    <div className="mrc-message" style={{
                        backgroundColor: "rgb(255, 235, 235)",
                        border: "1px solid #F66",
                        padding: "0.5% 2%",
                        margin: "10px 0",
                        borderRadius: "8px",
                    }}>
                        <span>The MRC portal data is secure and only made available to authorized users. This is not a public website and information needs to be treated as confidential.</span>
                    </div>

                    {/* RSVP Events */}
                    <div style={{ marginTop: "1vh", marginBottom: "20px" }}>
                        <RSVPUpcomingEventsMain memberID={this.props.memberID} />
                    </div>

                    {/* PowerBI Report */}
                    {embedInfo ? (
                        <Report
                            embedType="report"
                            tokenType="Embed"
                            accessToken={embedInfo}
                            embedUrl={embedAddress}
                            embedId={embedReportID}
                            extraSettings={extraSettings}
                            permissions="View"
                            style={{
                                height: `${viewportHeight - 200}px`, // Adjust dynamically
                                width: "100%",
                                marginTop: "20px",
                            }}
                        />
                    ) : (
                        <div style={{
                            color: "purple",
                            display: "flex",
                            alignItems: "center",
                            height: "300px",
                            width: "100%",
                            border: "1px solid purple",
                            padding: "20px",
                            borderRadius: "15px",
                            justifyContent: "center",
                        }}>
                            <Spinner animation="border" style={{ color: "purple", marginRight: "10px" }} />
                            <h1>Loading Dashboard Data...</h1>
                        </div>
                    )}
                </div>

                {/* Footer */}
                <NavBottom />
            </div>
        );
    }
}

export default Home;
