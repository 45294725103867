import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios'; 
import Table from "./RSVPTableTemplate";
import { Button, Spinner } from "react-bootstrap";

export function RSVPUpcomingEventsMain(props) {
    const [data, setData] = useState(0);

    useEffect(() => {
        (async () => {
            const result = await axios("https://prod-100.westus.logic.azure.com:443/workflows/a389ca49a9ec42a69ea800fa4bfb8597/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=U0ZtqNvNoa6Fo1OusEaUJfn6q5Gy3Qr3e6hjyXINsUk");
            setData(result.data);
        })();
    }, []);

    // Date formatting function
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const year = date.getFullYear().toString().slice(-2);
        const time = date.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true });

        return `${month}/${day}/${year} ${time}`;
    };

    // Setup Table
    const columns = useMemo(
        () => [
            {
                Header: "Upcoming Events",
                columns: [
                    {
                        Header: "Upcoming Events",
                        accessor: "Name"
                    },
                    {
                        Header: "Start Date",
                        accessor: "StartDate",
                        Cell: ({ value }) => formatDate(value),
                    }
                ]
            }
        ],
        []
    );

    return (
        <div
            style={{
                height: "38vh",
                display: "flex",
                flexDirection: "column",
                padding: "5px",
                border: "1px solid #ccc",
                borderRadius: "8px",
            }}
        >
            {data ? (
                <>
                    {/* The table wrapper for proper layout */}
                    <div style={{ flex: 1, overflowY: "auto", marginBottom: "10px" }}>
                        <Table columns={columns} data={data} />
                    </div>
                    {/* The button is outside the table container */}
                    <Button
                        variant="info"
                        className="mx-2 btn-events"
                        href="/rsvpeventspage"
                        style={{
                            backgroundColor: "#3A2C86",
                            color: "white",
                            marginLeft: "-1", // Moves button to the left
                            marginBottom: "5px", // Adds space below the button
                            alignSelf: "flex-start", // Aligns button to the left within parent
                        }}
                    >
                        Go to Event Registration
                    </Button>
                </>
            ) : (
                <div
                    className="div-loading"
                    style={{
                        color: "purple",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                    }}
                >
                    <Spinner animation="border" style={{ color: "purple" }} />
                    <h1>Fetching Event Data... </h1>
                </div>
            )}
        </div>
    );
}
