import React from 'react';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import NavTopSubMenu from './NavTopSubMenu';
import GoogleTranslate from './GoogleTranslate';
import '../App.css';

const NavTop = ({ memberID, logout }) => {
    return (
        <div>
            {/* Purple Navbar */}
            <Navbar className="navbar navbar-purple" variant="dark">
                <Container className="navbar-container">
                    {/* Brand Logo */}
                    <Navbar.Brand href="/" className="navbar-brand">
                        <img
                            src={`${process.env.PUBLIC_URL}/seiu.svg`}
                            alt="SEIU Logo"
                            className="nav-logo"
                        />
                    </Navbar.Brand>

                    {/* Web Layout: Phone Number */}
                    <a
                        href="tel:1-877-687-1021"
                        className="mrc-phone d-none d-md-block"
                        style={{ color: "white" }}
                    >
                        MRC: 1-877-687-1021
                    </a>

                    {/* Mobile Layout: Call Button */}
                    <Button
                        href="tel:1-877-687-1021"
                        variant="warning"
                        className="custom-mrc-button d-md-none"
                        style={{
                            height: "45px", // Set button height
                            lineHeight: "1.1", // Adjust line height for multi-line content
                            padding: "5px 10px", // Adjust padding for consistent appearance
                            borderRadius: "10px" // Slightly rounded corners
                        }}
                    >
                        Contact<br />MRC
                    </Button>


                    {/* Translation */}
                    <Nav className="ms-auto">
                        <GoogleTranslate />
                    </Nav>
                </Container>
            </Navbar>

            {/* Gray Navigation Bar */}
            <Navbar expand="md" className="navbar navbar-gray" variant="light">
                <Container className="subnav-container">
                    <NavTopSubMenu logout={logout} />
                </Container>
            </Navbar>
        </div>
    );
};

export default NavTop;