
import React, { Component } from 'react';
import axios from 'axios'; 
import NavBottom from './NavBottom';
const messageLoading = "Loading, pleae wait..."
const messageNoRecordsFound = "Sorry, no contracts were found matching your Member ID"

// Returns an array of contracts in list items (LI) 
// The output is either one list item with "No contracts to display", a single contract, or multiple contracts
const contracts = (contractData) => {
  // we could parse the json here but it's actually more realistic to simply look for the {
  const liContracts = [];

  if (contractData === messageLoading || contractData === messageNoRecordsFound){
    liContracts.push(<li style={{ listStyleType: "none" }} key='1'>{ contractData }</li>);
  }
  else {
    if (typeof(contractData) == 'string') { //&& !contractData.startsWith('https')) 
        // only one contract was returned
        liContracts.push(<li style={{ listStyleType: "none" }} key="1"><a className="dropdown-item" href={ contractData } eventKey="4.1" target="newwin"> { contractData }</a></li>);
      }
      else {
        // There were multiple contracts returned
        let i = 1;
        while  (typeof(contractData[i]) !== 'undefined'){
          liContracts.push(<li style={{ listStyleType: "none" }} key={i}><a className="dropdown-item" href={ contractData[i] } eventKey="4.1" target="newwin"> { contractData[i] }</a></li>);
          i++;
        }
    }
  }

  // Return the array of contract list items
  return liContracts;
}


export class ContractDocs extends Component {

      state = {
        contractQueryResult: messageLoading
      }

      componentDidMount() {
        var user = "&memberid=" + this.props.memberID;
        //var user = "&memberid=706288";
        //var user = "&memberid=643940";
        //var user = "&memberid=109676";

        var queryAddress = "https://prod-122.westus.logic.azure.com/workflows/5ff096311585438f8c1b6931610b64c3/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=cQ9P9upx6bNQnc-SUTzAg0Zj5oYKnzL97aw4t_vWR6I" + user;

        axios.get(queryAddress).then(response => response.data)
        .then((data) => {
          this.setState({ contractQueryResult: data })
        })      
        .catch((error) => {
          this.setState({ contractQueryResult: messageNoRecordsFound })
        })  
      }




      render() {
        const contractsList = contracts(this.state.contractQueryResult);
        return (
          <div>
            <div style={{"display" : "flex", "flexDirection" : "row", "alignItems" : "center", "height" : "calc(100vh - 620px)"}}>
              <div style={{"margin" : "auto", "color" : "purple","alignItems" : "left", "height" : "400px", "border" : "3px solid Purple", "padding" : "10px", "borderRadius" : "15px"}}>
                  <div style={{"margin" : "auto"}}><h3>Your Contract info can be found here.</h3>
                  </div>
                  <div>
                    <ul> {contractsList} </ul>
                  </div>
              </div>
            </div>
            <NavBottom />
            </div>
        );
    }
}

