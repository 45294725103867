import React, { useState, useEffect, useMemo, useCallback } from "react";
import axios from "axios";
import Table from "./RSVPTableTemplate";
import { Spinner } from "react-bootstrap";

const NODATA = "You are not registered for any events.";

export function RSVPRegisteredEvents({ memberID }) {
  const [data, setData] = useState(null); // Null instead of 0 for better checking
  const [loading, setLoading] = useState(true); // Start with true

  // Unregister Event
  const unregister = useCallback(
    async (eventRegistrationID) => {
      console.log(`Unregistering member ${memberID} with eventregid ${eventRegistrationID}`);

      const axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      };

      const uri = `https://prod-65.westus.logic.azure.com/workflows/4c61cc77ccfc4735aff8774c552fd495/triggers/manual/paths/invoke/${eventRegistrationID}?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=YgojLRCQI2VtV3Xq81g8GO_H-jqupXbfcBfHriH7Uvo`;

      try {
        await axios.get(uri, axiosConfig);
        alert("Successfully unregistered from event.");
        window.location.reload();
      } catch (error) {
        console.error("Error unregistering from event:", error);
      }
    },
    [memberID]
  );

  // Fetch Registered Events
  useEffect(() => {
    const fetchEvents = async () => {
      setLoading(true);
      try {
        const result = await axios.get(
          `https://prod-52.westus.logic.azure.com/workflows/db71493270564ed19d57674acc60cebc/triggers/manual/paths/invoke/${memberID}?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=r1RHW-JN767bYHKn8yEoINy0tjTlktwWFSJRJ_sP5EA`
        );
        setData(result.data.length > 0 ? result.data : null); // Only set data if events exist
      } catch (error) {
        console.error("Error fetching registered events:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, [memberID]);

  // Date formatting function
  const formatDateTime = (startDateString, endDateString) => {
    const startDate = new Date(startDateString);
    const endDate = new Date(endDateString);

    const formattedStartDate = `${startDate.getMonth() + 1}/${startDate.getDate()}/${startDate
      .getFullYear()
      .toString()
      .slice(-2)} ${startDate.toLocaleTimeString([], { hour: "numeric", minute: "2-digit", hour12: true })}`;

    const formattedEndTime = endDate.toLocaleTimeString([], { hour: "numeric", minute: "2-digit", hour12: true });

    return `${formattedStartDate} - ${formattedEndTime}`;
  };

  // Define Table Columns
  const columns = useMemo(
    () => [
      {
        Header: "Your Registered Events",
        columns: [
          {
            Header: "Registered Events",
            accessor: (d) => `${d.Name} - ${d.EventID}`,
          },
          {
            Header: "Date",
            accessor: (d) => formatDateTime(d.StartDateTime, d.EndDateTime),
          },
          {
            Header: "Location",
            accessor: "LocationName",
          },
          {
            Header: "Register",
            accessor: (d) => (
              <button onClick={() => unregister(d.RegistrationID)}>Unregister</button>
            ),
          },
        ],
      },
    ],
    [unregister]
  );

  return (
    <div>
      {loading ? (
        <div className="div-loading" style={{ color: "purple", display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
          <Spinner animation="border" style={{ color: "purple" }} />
          <h1>Fetching Registered Events...</h1>
        </div>
      ) : data ? (
        <Table columns={columns} data={data} style={{ margin: "0 auto", width: "80%" }} />
      ) : (
        <div className="div-loading" style={{ color: "purple", display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
          <h1>{NODATA}</h1>
        </div>
      )}
    </div>
  );
}