// Enter here the user flows and custom policies for your B2C application
// To learn more about user flows, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
// To learn more about custom policies, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview

export const b2cPolicies = {
    names: {
        signUpSignIn: "b2c_1a_seiu_signup_signin",
        forgotPassword: "B2C_1A_SEIU_PasswordResetSIGNIN"
    },
    authorities: {
        signUpSignIn: {
            authority: "https://seiu1021B2C.b2clogin.com/seiu1021B2C.onmicrosoft.com/B2C_1A_SEIU_SIGNUP_SIGNIN",
        },
        forgotPassword: {
            authority: "https://seiu1021B2C.b2clogin.com/seiu1021B2C.onmicrosoft.com/B2C_1A_SEIU_PasswordReset_SIGNIN",
        },
    },
    authorityDomain: "seiu1021B2C.b2clogin.com",
};