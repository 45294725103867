import React, { useEffect } from 'react';

const GoogleTranslate = () => {
    useEffect(() => {
        const addGoogleTranslateScript = () => {
            if (!document.getElementById('google-translate-script')) {
                const script = document.createElement('script');
                script.id = 'google-translate-script';
                script.src = "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
                script.async = true;
                document.body.appendChild(script);
            }
        };

        // Initialize Google Translate widget with top 20 languages
        window.googleTranslateElementInit = () => {
            new window.google.translate.TranslateElement(
                {
                    pageLanguage: 'en',
                    includedLanguages: 'en,es,zh-CN,tl,vi,ru,fr,ko,fa,de,ja,it,pt,hi,ar,pa,th,hy,he,el', // List of top 20 languages by code
                },
                'google_translate_element'
            );
        };

        addGoogleTranslateScript();
    }, []);

    return <div id="google_translate_element" style={{ minWidth: '130px' }} />;
};

export default GoogleTranslate;
